import router from './router'
import store from './store'
import {commonRoutes, adminRoutes, schoolRoutes, teacherRoutes, studentRoutes} from '@/router'

const whiteList = ['login', 'studentLogin'] // 不需要登录就可以访问的路由白名单,

router.beforeEach(async (to, from, next) => {
    // set page title
    document.title = to.meta.title

    // 定义2个端各自的token，方便走各自的路由逻辑，互不干扰
    const rolesToken = {
        1: 'adminToken',
        2: 'schoolToken',
        3: 'trainToken',
        4: 'teacherToken',
        5: 'studentToken',
    }

    const hasToken = localStorage.getItem(rolesToken[to.meta.role]) // 获取当前访问客户端的token
    if (!hasToken) {
        /* has no token*/
        if (whiteList.indexOf(to.name) !== -1) {
            next()
            return
        }
        if (to.meta.role != 5) {
            next('/m')
            return
        }
        next('/')
    }

    // 如果访问的路由存在对应的token，走各自流程
    switch (to.meta.role) {
        case 1:
            const adminMenu = store.getters.adminMenu && store.getters.adminMenu.length > 0;
            if (!adminMenu) {
                store.dispatch('generateAdminRoutes', adminRoutes)
            }
            next()
            break;
        case 2:
            const schoolMenu = store.getters.schoolMenu && store.getters.schoolMenu.length > 0;
            if (!schoolMenu) {
                store.dispatch('generateSchoolRoutes', schoolRoutes)
            }
            next()
            break;
        case 4:
            const teacherMenu = store.getters.teacherMenu && store.getters.teacherMenu.length > 0;
            if (!teacherMenu) {
                store.dispatch('generateTeacherRoutes', teacherRoutes)
            }
            next()
            break;
        case 5:
            const studentMenu = store.getters.studentMenu && store.getters.studentMenu.length > 0;
            if (!studentMenu) {
                store.dispatch('generateStudentRoutes', studentRoutes)
            }
            next()
            break;
        default:
            next()
    }
})

router.afterEach(() => {
})